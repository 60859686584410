import classNames from "classnames";
import { format, isToday } from "date-fns";
import React, { useMemo } from "react";

import Badge from "../../../../shared/components/design-system/Badge";
import { TextWithParsedLinks } from "../../../../shared/components/design-system/Button/Link";
import { useTranslate } from "../../../translation/frontend";
import { Message as MessageType } from "../../hooks/useMessages";
import styles from "./styles.module.scss";

function Message({ message: { fromCaseworker, createdAt, body, redacted } }: { message: MessageType }) {
  const translate = useTranslate();
  const messageClass = classNames(styles.message, {
    [styles.caseworker]: fromCaseworker,
  });

  const formattedTimestamp = useMemo(() => {
    const date = new Date(createdAt);
    return `${format(date, "H:mm")}, ${
      isToday(date)
        ? translate("today")
        : date.toLocaleDateString("en-GB", { year: "numeric", month: "numeric", day: "numeric" })
    }`;
  }, [createdAt, translate]);

  return (
    <div className={messageClass}>
      <div className={styles.messageContainer}>
        <span className={styles.messageInfo}>
          {translate(fromCaseworker ? "caseWorker" : "you")} - {formattedTimestamp}
        </span>
      </div>
      <div className={styles.messageRow}>
        <p className={styles.messageBody} data-test-id="message-body">
          <TextWithParsedLinks text={body} />
        </p>
        {redacted ? (
          <div>
            <Badge variant="warning" label={translate("redacted")} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Message;
