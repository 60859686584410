import React, { PropsWithChildren } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

import { Translatable, Translation } from "../../translation";
import Icon from "../Icon";
import { LinkClassProps, linkClassName } from "./styles";

type LinkProps = LinkClassProps & {
  to: string;
  onClick?: () => void;
} & ({ children?: undefined; label: Translatable } | PropsWithChildren<{ label?: undefined }>);

export function InternalLink({
  to,
  onClick,
  label,
  children,
  state,
  ...props
}: LinkProps & Pick<RouterLinkProps, "state">) {
  return (
    <RouterLink className={linkClassName(props)} to={to} onClick={onClick} state={state}>
      {label === undefined ? children : <Translation props={label} />}
    </RouterLink>
  );
}

export function ExternalLink({
  to,
  onClick,
  label,
  children,
  suppressIcon,
  ...props
}: LinkProps & { suppressIcon?: boolean }) {
  return (
    <a onClick={onClick} className={linkClassName(props)} href={to} target="_blank" rel="noreferrer noopener">
      {label === undefined ? children : <Translation props={label} />}
      {suppressIcon ? null : <Icon inline icon="arrowUpRightFromSquare" size={20} className="ds-ml-3" />}
    </a>
  );
}

// eslint-disable-next-line security/detect-unsafe-regex
const urlMatcher = /^(https?:\/\/)?([\w-]+\.)+\w{2,10}(\/[-\w?@%&+~#=,;/]*)?$/;
// eslint-disable-next-line security/detect-unsafe-regex
const phoneMatcher = /^\+?(\d-?){8,13}$/;
// eslint-disable-next-line security/detect-unsafe-regex
const emailMatcher = /^[\w-.]+@([\w-]+\.)+\w{2,10}$/;

// ExternalLink seems to require a http protocol in front of the url,
// which may or may not have been added in the messaging box,
// so if there's no protocol then assume HTTPS
const urlWithProtocol = (url: string) => {
  if (url.includes("://")) return url;
  return "https://" + url;
};

export const TextWithParsedLinks = ({ text }: { text: string }) => {
  // We need to split our input text by space and new line for this to work properly
  const textChunks = text.split(/(\s+)/g);

  return (
    <>
      {textChunks.map((chunk, i) => {
        if (emailMatcher.test(chunk)) {
          return <ExternalLink key={i} to={"mailto:" + chunk} label={chunk} suppressIcon />;
        }
        if (urlMatcher.test(chunk)) {
          // for some reason sending the chunk as a child fixes an issue where the url protocol didn't render correctly
          // note that the url label needs to be set with {{ literal: chunk }} or the translation system will try to parse it
          return <ExternalLink key={i} to={urlWithProtocol(chunk)} label={{ literal: chunk }} suppressIcon />;
        }
        if (phoneMatcher.test(chunk)) {
          return <ExternalLink key={i} to={"tel:" + chunk} label={chunk} suppressIcon />;
        }
        return <React.Fragment key={i}>{chunk}</React.Fragment>;
      })}
    </>
  );
};
